<template>
  <div class="menu">
    <div
      class="menu__activator"
      @click.prevent="isShow = !isShow"
      ref="activator"
    >
      <slot
        class="menu__icon"
        v-if="$slots.activator"
        name="activator"
      />
      <r-icon
        v-else
        class="menu__icon"
        icon="menu-context"
        size="16"
        fill="rocky"
      />
    </div>
    <div
      v-show="isShow"
      class="menu__list"
      :style="position"
      @click="isShow = !isShow"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      default: () => ({
        top: 'calc(100% - 25px)',
        right: '0'
      })
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  mounted() {
    addEventListener('click', this.onMouseClick);
  },
  beforeDestroy() {
    removeEventListener('click', this.onMouseClick);
  },
  methods: {
    onMouseClick(e) {
      if (e.target === this.$refs.activator) {

      } else {
        this.isShow = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  display: contents;
  position: relative;
  &__activator {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__icon {
    pointer-events: none;
  }
  &__list {
    position: absolute;
    background: #fff;
    box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
    border-radius: 8px;
    padding: 24px 20px;
    z-index: 10;
  }
}
</style>
