import ApiService from './ApiService';

export default class Api extends ApiService {
  // - справочник типов клиник, ФИО для дропдауна, координаты для карты
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctors', {
      readOnly: 1
    });
    return data;
  }

  // $.postJSON("/ajax.php?action=getDoctorHouses|getDoctorDistricts|getDoctorStaff|getDoctorClinics",{readOnly:1,type:type})
  // - квартирные адреса по участкам
  // - геометрия
  // - врачи
  // - клиники

  async getDoctorHouses(payload) {
    const { data } = await this._axios.post(
      'ajax.php?action=getDoctorDistricts&orderBy=district',
      payload
    );
    return data;
  }

  async getStaff(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctorStaff&orderBy=name', payload);
    return data;
  }

  async getClinics(payload) {
    const { data } = await this._axios.post(
      'ajax.php?action=getDoctorClinics&orderBy=name',
      payload
    );
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('mob_ajax.php?action=geosearch', payload);
    return data;
  }

  // fetch(API_URL, { method: 'POST', body: formData })
  // url="https://test.rosatom.city/mob_ajax.php?action=geosearch"
  // :fetchParams="{
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json;charset=utf-8'
  //   },

  //   body: JSON.stringify({ address })
  // }"

  //   $.postJSON("/ajax.php?action=operateDoctorHouses|operateDoctorDistricts|operateDoctorStaff|operateDoctorClinics",json)
  // json={id:Int,action:update|delete|save|save2}
  // save - для geometry[[]..], save2 - для coordinates[]

  async setStaff(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateDoctorStaff', {
      ...payload,
      action: 'update'
    });
    return data;
  }

  async setDistrict(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateDoctorDistricts', {
      ...payload,
      action: 'update'
    });
    return data;
  }

  async setClinic(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateDoctorClinics', {
      ...payload,
      action: 'update'
    });
    return data;
  }

  async deleteDistrict(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateDoctorDistricts', {
      ...payload,
      action: 'delete'
    });
    return data;
  }

  async deleteStaff(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateDoctorStaff', {
      ...payload,
      action: 'delete'
    });
    return data;
  }

  async deleteClinic(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateDoctorClinics', {
      ...payload,
      action: 'delete'
    });
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.post('nginxApi.php?get=user');
    return data;
  }

  async logOut() {
    const { data } = await this._axios.post('auth/rest/logout');
    return data;
  }
}
