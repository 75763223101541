<template>
  <div class="staff-form flex flex-column">
    <admin-header
      :title="!isEditPage ? 'Новый врач' : 'Редактирование сотрудника'"
      :is-save="!isSave && isEditPage"
    />
    <div class="staff-form__main-grid mt-8">
      <img-uploader
        class="staff-form__img-cont"
        url="/uploadfile/index.php"
        @change="uploadImage"
        :src="imageUrl"
        @onViewImage="onViewImage"
        @deleteFile="deleteFile"
      />

      <div class="flex flex-1 align-start flex-1">
        <div class="staff-form__inputs flex-1">
          <r-select
            label="Тип"
            :items="types"
            select-first-item
            v-model="selectedType"
            @input="isChange('selectedType')"
          />
          <r-input
            label="ФИО"
            v-model="name"
            @blur="isChange('name')"
          />
          <r-input
            label="Должность"
            v-model="position"
            @blur="isChange('position')"
          />
        </div>
      </div>

      <!--      <div class="flex align-start flex-1">-->
      <!--        <div class="flex-1 mr-2">-->
      <!--          <rir-select-->
      <!--            :items="avialableDistricts"-->
      <!--            label="Участок"-->
      <!--            v-model="selectedDistrict"-->
      <!--            returnObject-->
      <!--          />-->
      <!--          <ContactItems-->
      <!--            class="mt-4"-->
      <!--            v-show="attachedDistricts.length"-->
      <!--            icon="doc"-->
      <!--            textValue="value"-->
      <!--            subValue="address"-->
      <!--            v-model="attachedDistricts"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <rir-button @click="attachDistrict" icon="add" :disabled="!selectedDistrict" />-->
      <!--      </div>-->
    </div>
    <div
      class="flex align-center custom mt-10 mb-60px pointer fargo-hover"
      @click="DeleteItem"
      v-if="isEditPage"
    >
      <r-icon
        icon="delete"
        fill="fargo"
      />
      <div class="fargo--text ml-2 text-c16">
        Удалить врача
      </div>
    </div>
    <div class="staff-form__btn-cont">
      <r-button
        @click="submit"
        :disabled="isEditPage ? isSave : null"
        :title="isEditPage ? 'Сохранить' : 'Добавить'"
        width="wide"
      />
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import ModalCrop from '@/components/ModalCrop.vue';
import ImgUploader from '@/components/ImgUploader.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import ContactItems from '@/components/ContactItems.vue';
import ModalViewImage from '@/components/ModalViewImage';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';

export default {
  components: {
    ModalCrop,
    ImgUploader,
    AdminHeader,
    ContactItems
  },
  data() {
    return {
      name: null,
      position: null,
      selectedType: null,
      imageUrl: null,
      isLoading: false,
      loadedImg: null,
      attachedDistricts: [],
      selectedDistrict: {},
      initialData: {},
      isSave: true
    };
  },

  computed: {
    doctor() {
      return this.$store.getters.getStaffById(this.$route.query.id);
    },
    districtList() {
      if (!this.selectedType) return [];

      const districts = this.$store.state.districts
        .filter(el => el.type === String(this.selectedType))
        .map(el => ({
          id: el.id,
          district: el.district,
          value: `Участок №${el.district}`,
          address: el.address,
          type: el.type
        }));

      return districts;
    },

    avialableDistricts() {
      if (!this.attachedDistricts.length) {
        return this.districtList;
      }
      const selectedIds = this.attachedDistricts.map(el => el.id);
      console.log('selecteddDSTW', this.attachedDistricts, this.districtList);
      return this.districtList.filter(el => !selectedIds.includes(el.id));
    },

    isEditPage() {
      console.log(this.$route.query.id && !this.isCopy);
      return this.$route.query.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    },
    types() {
      return this.$store.getters.getDocTypes.map(el => ({
        id: el.Id,
        title: el.Name
      }));
    }
  },

  async mounted() {
    await this.$store.dispatch('loadTypes');
    console.log('1', this.$store.state.staff);
    await this.$store.dispatch('getStaff');
    await this.$store.dispatch('getDistricts');
    console.log('2', this.$store.state.staff);
    console.log('st', this.isEditPage);
    (this.isEditPage || this.isCopy) && this.setData(this.isEditPage);
  },

  // beforeRouteEnter(to, from, next) {
  //   console.log('to from', to, from);
  //   if (to.query.id) {
  //     next(vm => {
  //       vm.resetData();
  //       vm.setData(to.query.id);
  //     });
  //   } else {
  //     next(vm => vm.resetData());
  //   }
  // },
  activated() {
    this.resetData();
    if (this.isEditPage || this.isCopy) {
      this.setData(this.$route.query.id);
    }
  },
  deactivated() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.loadedImg?.src) {
      URL.revokeObjectURL(this.loadedImg.src);
    }
  },
  methods: {
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.save,
        title: this.name,
        actionPostfix: 'Staff'
      });
    },
    isChange(field) {
      if (this.initialData[field] === this[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    async DeleteItem() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$route.params.id,
        actionPostfix: 'Staff'
      });
    },
    attachDistrict() {
      this.attachedDistricts.push(this.selectedDistrict);
      this.selectedDistrict = {};
    },
    onViewImage() {
      this.$refs.modal.openModal(ModalViewImage, {
        file: this.imageUrl,
        ratio: '1/1'
      });
    },
    deleteFile() {
      this.imageUrl = null;
    },
    uploadImage(file) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$refs.modal.openModal(ModalCrop, { image: this.loadedImg });

      console.log('ii', this.loadedImg);
      this.isChange('imageUrl');
      // }
    },
    async save() {
      console.log('attached', this.attachedDistricts);
      const data = {
        id: this.isEditPage ? this.$route.query.id : -1, // Отрицательный id необходим для добавления нового объекта
        name: this.name,
        img: this.imageUrl,
        type: String(this.selectedType),
        position: this.position
        // districts: this?.attachedDistricts?.length
        //   ? this.attachedDistricts.map(el => el.district)
        //   : []
      };
      this.isLoading = true;
      await this.$store.dispatch('setStaff', data);
      await this.$store.dispatch('getStaff');
      this.isLoading = false;
      // this.$router.push({ name: 'admin' });
      const r = this.$router.resolve({
        name: 'admin'
      });
      window.location.assign(r.href);
    },

    resetData() {
      this.name = null;
      this.position = null;
      this.selectedType = null;
      this.imageUrl = null;
      this.isLoading = false;
      this.loadedImg = null;
      this.attachedDistricts = [];
      this.selectedDistrict = {};
    },
    setData() {
      const staff = this.doctor;
      console.log('staffById', staff);
      this.name = staff.name;
      this.position = staff.position;
      this.selectedType = +staff.type;
      this.attachedDistricts = staff?.districts?.length > 0
        ? [...this.districtList.filter(el => staff.districts.includes(+el.district))]
        : [];
      this.imageUrl = staff.img;
      this.isLoading = false;
      this.loadedImg = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.staff-form {
  min-height: 100vh;
  padding-bottom: 130px;
  &__main-grid {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-gap: 24px;
  }
  &__img-cont {
    border-radius: 50%;
  }
  &__inputs {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #f6f9fe;
    z-index: 2;
    padding: 32px 40px 40px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}
::v-deep .rir-drag-upload-file {
  border: none;
}
::v-deep .rir-modal__content {
  overflow: visible;
}
</style>
