<template>
  <div class="district-form flex flex-column">
    <admin-header
      :title="(!isEditPage ? 'Новый участок' : (districtNumber ? 'Участок №'+districtNumber : ''))
      "
      :is-save="!isSave && isEditPage"
    />
    <div class="mt-8 flex flex-1">
      <div class="flex flex-1 flex-column">
        <r-input
          label="Номер участка"
          v-model="districtNumber"
          @blur="isChange('districtNumber')"
        />
        <r-select
          class="mt-6"
          :items="types"
          label="Тип"
          select-first-item
          v-model="selectedType"
          @input="isChange('selectedType')"
        />
        <r-select
          class="mt-6"
          :items="institutionList"
          label="Учреждение"
          v-model="institution"
          return-object
          @input="isChange('institution')"
        />

        <div class="mt-6 flex align-start">
          <div class="flex-1 mr-2">
            <r-select
              :items="avialableStaffList"
              label="Врачи"
              v-model="staff"
              return-object
            />
            <contact-items
              class="mt-4"
              v-show="selectedStaff.length"
              icon="avatar"
              text-value="title"
              sub-value="position"
              v-model="selectedStaff"
            />
          </div>
          <r-button-simple
            size="larishae"
            type="primary"
            color="rocky"
            :disabled="!staff.id"
            @click="pushStaff"
            icon="add"
          />
        </div>
        <r-textarea
          class="mt-6"
          :key="$route.query.id"
          v-model="addresses"
          label="Обслуживаемые адреса"
          @input="isChange('institution')"
        />
      </div>

      <div
        class="ml-6 flex-1 district-form__map overflow-hidden"
        :key="count"
      >
        <rir-map
          @onRightClick="onMapRightClick"
          @click="onMapClick"
          @onInit="onMapInit"
          @markersWasAdd="markersWasAdd"
          v-if="isShowMap"
        >
          <ymap-marker
            v-if="institution.coords"
            :coords="institution.coords"
            :marker-id="'marker'"
            :icon="$markerIcon()"
          />

          <div v-if="isShowMap">
            <div
              v-if="countArrays(geometry) >= 4"
              v-for="(pol, index) in geometry"
              :key="index"
            >
              <div
                v-for="(pol, index) in geometry"
                :key="index + districtNumber"
              >
                <ymap-marker
                  @click="onPolygonClick"
                  @geometrychange="geometryChange"
                  :marker-id="`polygon_marker_${index}`"
                  marker-type="polygon"
                  :coords="pol"
                  :hint-content="`Участок №${districtNumber}`"
                  :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: '#3d75e4', width: 1 }"
                  :options="{
                    /** Недокументированная опция!
                Задает активную область над кастомной иконкой точки (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                    editorVertexIconShape: {
                      type: 'Rectangle',
                      coordinates: [
                        [-4, -4],
                        [4, 4]
                      ]
                    },
                    /** Недокументированная опция!
                Задает активную область над промежуточнй точкой (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                    editorEdgeIconShape: {
                      type: 'Rectangle',
                      coordinates: [
                        [-4, -4],
                        [4, 4]
                      ]
                    },
                    editorVertexLayout: yaMapPoint,
                    editorVertexLayoutHover: yaMapPoint,
                    editorVertexLayoutActive: yaMapPoint,
                    editorVertexLayoutDrag: yaMapPoint,
                    editorEdgeLayout: yaMapPointEdge,
                    editorEdgeLayoutHover: yaMapPointEdge,
                    editorEdgeLayoutActive: yaMapPointEdge,
                    editorEdgeLayoutDrag: yaMapPointEdge
                  }"
                  fill-rule="nonZero"
                />
              </div>
            </div>
            <div
              v-if="countArrays(geometry) == 3"
              v-for="(pol, index) in [geometry]"
              :key="index"
            >
              <div
                v-for="(pol, index) in [geometry]"
                :key="index + districtNumber"
              >
                <ymap-marker
                  @click="onPolygonClick"
                  @geometrychange="geometryChange"
                  :marker-id="`polygon_marker_${index}`"
                  marker-type="polygon"
                  :coords="pol"
                  :hint-content="`Участок №${districtNumber}`"
                  :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: '#3d75e4', width: 1 }"
                  :options="{
                    /** Недокументированная опция!
                Задает активную область над кастомной иконкой точки (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                    editorVertexIconShape: {
                      type: 'Rectangle',
                      coordinates: [
                        [-4, -4],
                        [4, 4]
                      ]
                    },
                    /** Недокументированная опция!
                Задает активную область над промежуточнй точкой (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                    editorEdgeIconShape: {
                      type: 'Rectangle',
                      coordinates: [
                        [-4, -4],
                        [4, 4]
                      ]
                    },
                    editorVertexLayout: yaMapPoint,
                    editorVertexLayoutHover: yaMapPoint,
                    editorVertexLayoutActive: yaMapPoint,
                    editorVertexLayoutDrag: yaMapPoint,
                    editorEdgeLayout: yaMapPointEdge,
                    editorEdgeLayoutHover: yaMapPointEdge,
                    editorEdgeLayoutActive: yaMapPointEdge,
                    editorEdgeLayoutDrag: yaMapPointEdge
                  }"
                  fill-rule="nonZero"
                />
              </div>
            </div>
            <div
              :key="district.id"
              v-for="district in polygonsOther"
            >
              <div
                v-if="countArrays(district.geometry) == 3"
                v-for="(pol, index) in [geometry]"
                :key="index"
              >
                <div
                  v-for="(pol, index) in [district.geometry]"
                  :key="`${district.id}_${index}`"
                >
                  <ymap-marker
                    :key="`district_${district.id}_${index}_marker`"
                    :marker-id="`district_${district.id}`"
                    marker-type="Polygon"
                    :coords="pol"
                    :hint-content="`Участок №${district.district}`"
                    :marker-fill="{ color: '#6f7a90', opacity: 0.16 }"
                    :marker-stroke="{ color: '#6f7a90', width: 1 }"
                    :options="{
                      /** Недокументированная опция!
                Задает активную область над кастомной иконкой точки (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorVertexIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      /** Недокументированная опция!
                Задает активную область над промежуточнй точкой (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorEdgeIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      editorVertexLayout: yaMapPoint,
                      editorVertexLayoutHover: yaMapPoint,
                      editorVertexLayoutActive: yaMapPoint,
                      editorVertexLayoutDrag: yaMapPoint,
                      editorEdgeLayout: yaMapPointEdge,
                      editorEdgeLayoutHover: yaMapPointEdge,
                      editorEdgeLayoutActive: yaMapPointEdge,
                      editorEdgeLayoutDrag: yaMapPointEdge
                    }"
                    fill-rule="nonZero"
                  />
                </div>
              </div>
              <div
                v-if="countArrays(district.geometry) >= 4"
                v-for="(pol, index) in [geometry]"
                :key="index"
              >
                <div
                  v-for="(pol, index) in district.geometry"
                  :key="`${district.id}_${index}`"
                >
                  <ymap-marker
                    :key="`district_${district.id}_${index}_marker`"
                    :marker-id="`district_${district.id}`"
                    marker-type="Polygon"
                    :coords="pol"
                    :hint-content="`Участок №${district.district}`"
                    :marker-fill="{ color: '#6f7a90', opacity: 0.16 }"
                    :marker-stroke="{ color: '#6f7a90', width: 1 }"
                    :options="{
                      /** Недокументированная опция!
                Задает активную область над кастомной иконкой точки (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorVertexIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      /** Недокументированная опция!
                Задает активную область над промежуточнй точкой (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorEdgeIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      editorVertexLayout: yaMapPoint,
                      editorVertexLayoutHover: yaMapPoint,
                      editorVertexLayoutActive: yaMapPoint,
                      editorVertexLayoutDrag: yaMapPoint,
                      editorEdgeLayout: yaMapPointEdge,
                      editorEdgeLayoutHover: yaMapPointEdge,
                      editorEdgeLayoutActive: yaMapPointEdge,
                      editorEdgeLayoutDrag: yaMapPointEdge
                    }"
                    fill-rule="nonZero"
                  />
                </div>
              </div>
            </div>
          </div>
        </rir-map>
      </div>
    </div>
    <div
      class="flex align-center custom mt-10 mb-60px pointer fargo-hover"
      @click="DeleteItem"
      v-if="isEditPage"
    >
      <r-icon
        icon="delete"
        fill="fargo"
      />
      <div class="fargo--text ml-2 text-c16">
        Удалить участок
      </div>
    </div>
    <div class="district-form__btn-cont">
      <r-button
        @click="submit"
        :disabled="isEditPage ? isSave : null"
        :title="isEditPage ? 'Сохранить' : 'Добавить'"
        width="wide"
      />
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import ContactItems from '@/components/ContactItems.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import RirMap from '@/components/RirMap.vue';
import { loadYmap } from '../vue-yandex-maps/vue-yandex-maps.umd';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';
import BalloonCard from '@/components/BalloonCard.vue';

export default {
  components: {
    BalloonCard,
    ContactItems,
    AdminHeader,
    RirMap
  },
  data() {
    return {
      addresses: null,
      districtNumber: null,
      districtType: null,
      institution: {},
      selectedInstitutions: [],
      selectedStaff: [],
      staff: {},
      selectedType: null,
      coords: [],
      count: 0,
      isLoading: true,
      yaMapPoint: null,
      yaMapPointEdge: null,
      mapInstance: null,
      isMapReady: false,
      newPolygonIndex: null,
      geometry: [],
      isShowMap: false,
      initialData: {
        addresses: null,
        coords: [],
        count: 0,
        districtNumber: 0,
        districtType: null,
        geometry: [],
        institution: {},
        isLoading: true,
        isMapReady: false,
        isSave: true,
        isShowMap: false,
        mapInstance: null,
        newPolygonIndex: null,
        selectedInstitutions: [],
        selectedStaff: [],
        selectedType: 0,
        staff: {},
        yaMapPoint: null,
        yaMapPointEdge: null
      },
      isSave: true
    };
  },

  computed: {
    polygonsOther() {
      return this.$store.state.districts.filter(
        el => String(el.id) !== String(this.$route.query.id)
          && !!el.geometry
          && el.type === String(this.selectedType)
      );
    },
    institutionList() {
      const list = this.$store.state.clinics.map(el => ({
        id: el.id,
        title: el.name,
        address: el.address,
        coords: el.lat && el.lng ? [el.lat, el.lng] : null
      }));
      return list;
    },

    staffList() {
      return this.$store.getters.getStaffByType(this.selectedType).map(el => ({
        id: el.id,
        title: el.name,
        position: el.position
      }));
    },

    avialableStaffList() {
      if (!this.selectedStaff.length) {
        return this.staffList;
      }
      const selectedIds = this.selectedStaff.map(el => el.id);
      return this.staffList.filter(el => !selectedIds.includes(el.id));
    },
    isEditPage() {
      return this.$route.query.id && !this.isCopy;
    },

    isCopy() {
      return !!this.$route.query.copy;
    },

    types() {
      return this.$store.getters.getDocTypes.map(el => ({
        id: el.Id,
        title: el.Name
      }));
    }
  },

  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async mounted() {
    await this.$store.dispatch('loadTypes');
    await this.$store.dispatch('getStaff');
    await this.$store.dispatch('getClinics');
    await this.$store.dispatch('getDistricts');
    const settings = { lang: 'ru_RU' };
    !window.ymaps && (await loadYmap(settings));
    this.yaMapPoint = window.ymaps.templateLayoutFactory.createClass(
      "<div class='ya-map-point'></div>"
    );
    this.yaMapPointEdge = window.ymaps.templateLayoutFactory.createClass(
      "<div class='ya-map-point--edge'></div>"
    );

    (this.isEditPage || this.isCopy) && this.setData();
  },
  methods: {
    countArrays(array) {
      let sumTotal = 1;

      for (const element of array) {
        if (Array.isArray(element)) {
          sumTotal += this.countArrays(element);
        }
      }

      return sumTotal;
    },
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.save,
        title: this.districtNumber,
        actionPostfix: 'District'
      });
    },
    isChange(field) {
      if (this.initialData[field] === this[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    async DeleteItem() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$route.params.id,
        actionPostfix: 'District'
      });
    },
    onMapInit(e) {
      this.mapInstance = e;
    },

    geometryChange(e) {
      this.drawTarget = e.originalEvent.target;
    },

    markersWasAdd(e) {
      //* После добавления объекта на карту, проверяем соответствует ли index текущего с index'ом последнего
      // добавленного

      if (this.newPolygonIndex === null) return;
      const res = e.find(
        obj => obj.properties._data.markerId === `polygon_marker_${this.newPolygonIndex}`
      );
      if (res) {
        res.editor.startDrawing();
        this.drawTarget = res;
      }
    },
    onPolygonClick(e) {
      // console.log('polygon click', e);
      this.drawTarget = e.originalEvent.target;
      e.originalEvent.target.editor.startEditing();
    },
    onMapRightClick(e) {
      this.drawTarget && this.drawTarget.editor.stopEditing();
    },
    async onMapClick(coords) {
      this.geometry.push([[coords]]);

      // Получаем index элемента нового полигона
      this.newPolygonIndex = this.geometry.length - 1;
    },

    async save() {
      const geometry = [];
      this.mapInstance.geoObjects.each(collection => {
        collection.each(geoObject => {
          // если в markerId есть строка marker, значит - маркер, а не полигон
          if (geoObject.properties._data.markerId.indexOf('polygon_marker') !== -1) {
            const geoCords = geoObject.geometry.getCoordinates();

            // Проверяем длину массива (кол-во точек фигуры)
            geoCords.flat(5).length >= 8 && geometry.push(geoObject.geometry.getCoordinates());
          }
        });
      });

      const data = {
        id: this.isEditPage ? this.$route.query.id : -1, // Отрицательный id необходим для добавления нового объекта
        district: this.districtNumber,
        type: String(this.selectedType),
        institutionId: this.institution.id,
        geometry,
        staff: this.selectedStaff.map(el => el.id),
        addresses: this.addresses
      };
      this.isLoading = true;
      await this.$store.dispatch('setDistrict', data);
      await this.$store.dispatch('getDistricts');
      this.isLoading = false;
      // this.$router.push({ name: 'admin' });
      const r = this.$router.resolve({
        name: 'admin'
      });
      window.location.assign(r.href);
    },

    pushStaff() {
      this.selectedStaff.push(this.staff);
      this.staff = {};
      this.isChange('selectedStaff');
    },
    resetData() {
      this.districtNumber = null;
      this.districtType = null;
      this.institution = {};
      this.selectedStaff = [];
      this.staff = {};
      this.selectedType = null;
      this.geometry = [];
      this.newPolygonIndex = null;
      this.addresses = null;
    },
    setData() {
      const district = this.$store.getters.getDistrictById(this.$route.query.id);
      this.selectedType = +district?.type || 0;
      this.districtNumber = district?.district || 0;
      this.addresses = district?.addresses || null;
      this.institution = this.institutionList.filter(el => el.id === district.institutionId)[0] || {};
      this.geometry = district?.geometry?.length ? district.geometry : [];

      this.selectedStaff = district?.staff.length > 0
        ? [...this.staffList.filter(el => district.staff.includes(+el.id))]
        : [];
    }
  }
};
</script>

<style lang="scss" scoped>
.district-form {
  min-height: 100vh;
  padding-bottom: 130px;
  &__btn-cont {
    padding: 32px 40px 40px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
  &__map {
    height: 500px;
    overflow: hidden;
  }
  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #f6f9fe;
    z-index: 2;
    padding: 32px 40px 40px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}
::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}
</style>
