<template>
  <div class="obj-card">
    <div :class="['obj-card__img', { 'opacity-32': purgeDeadline, 'rounded-full': imgBorder }]">
      <img
        v-if="img"
        :src="img"
        alt=""
        loading="lazy"
      >
      <r-icon
        v-else
        :icon="icon ? icon : 'mayor'"
        size="32"
        fill="alien"
      />
    </div>
    <div class="obj-card__info flex-1">
      <div :class="['obj-card__title sulguni', { 'opacity-32': purgeDeadline }]">
        {{ title }}
      </div>
      <div :class="['obj-card__address mozzarella', { 'opacity-32': purgeDeadline }]">
        {{ address }}
      </div>

      <div class="obj-card__counters flex align-center">
        <slot />
      </div>
    </div>

    <div class="flex align-center">
      <additional-menu v-if="!purgeDeadline">
        <ul class="opacity-72">
          <li
            class="sulguni flex pointer"
            @click.stop.prevent="copyItem"
          >
            <r-icon
              class="mr-3"
              icon="copy"
              fill="rocky"
            />Дублировать
          </li>
          <li
            class="sulguni flex mt-6 pointer"
            @click.stop.prevent="deleteItem"
          >
            <r-icon
              class="mr-3"
              icon="delete"
              fill="fargo"
            />Удалить
          </li>
        </ul>
      </additional-menu>
      <button
        class="obj-card__restore"
        v-else
        @click.stop.prevent="onRestore"
      >
        <r-icon
          icon="restore"
          fill="rocky"
        />
      </button>
    </div>
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';

export default {
  components: { AdditionalMenu },
  props: {
    uid: {
      type: String,
      required: true
    },
    img: {
      type: [String, null],
      default: null
    },
    imgBorder: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    purgeDeadline: {
      type: [String, null],
      default: null
    },
    currentTab: {
      type: String,
      required: true
    }
  },
  computed: {
    actionPostfix() {
      let postfix = null;
      if (this.currentTab === 'districts') {
        postfix = 'District';
      } else if (this.currentTab === 'staff') {
        postfix = 'Staff';
      } else if (this.currentTab === 'institutions') {
        postfix = 'Institution';
      }
      return postfix;
    }
  },
  methods: {
    async onRestore() {
      console.log('restore', this.uid);

      if (this.actionPostfix) {
        const res = await this.$store.dispatch(`set${this.actionPostfix}`, {
          id: this.uid,
          hidden: 0
        });
        res && this.$store.dispatch(`get${this.actionPostfix}`);
      }
    },
    copyItem() {
      this.$router.push({ name: this.currentTab, query: { id: this.uid, copy: true } });
    },
    async deleteItem() {
      if (this.actionPostfix) {
        const res = await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid);
        res && this.$store.dispatch(`get${this.actionPostfix}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;
  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;
    > img {
      height: 100%;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__counters {
    margin-top: 14px;
  }
  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
  .pointer {
    cursor: pointer;
  }
}
.opacity-32 {
  opacity: 0.32;
}
</style>
