<template>
  <div class="flex">
    <div class="upload">
      <div class="parent align-center">
        <div class="container-upload">
          <h1 class="ricotta mb-4">
            Сохранить изменения?
          </h1>
          <p class="mb-8 font">
            Вы внесли изменения
            <template v-if="actionPostfix === 'Institution'">
              в учреждение
            </template>
            <template v-if="actionPostfix === 'District'">
              в участок
            </template>
            <template v-if="actionPostfix === 'Staff'">
              в карточке врача
            </template>
            «{{ title }}»
          </p>
          <div class="mt-2 buttons">
            <r-button
              class="flex-1"
              color="secondary"
              @click="close()"
              width="wide"
              title="Не сохранять"
            />
            <r-button
              class="flex-1"
              @click.native="save"
              width="wide"
              title="Да, сохранить"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    saveFunc: Function,
    title: String,
    actionPostfix: String
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    async save() {
      this.saveFunc();
    }
  }
};
</script>

<style lang="scss" scoped>
.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 50%;
  margin-left: 33%;
  margin-top: -150px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.delete-warn {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--rir-amelie);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.notSave {
  width: 100%;
  display: inline-block;
}

.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }
}

.icon__close {
  top: 12px;
  right: 2px;
  position: absolute;
}

.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }

}
</style>
<style>
.RModal__close {
  z-index: 1000;
}
</style>
