<template>
  <div class="r-map-balloon">
    <img
      v-if="img"
      class="r-map-balloon__img"
      :src="img"
      :alt="title"
    >
    <div class="r-map-balloon__info">
      <p class="roquefort">
        {{ title }}
      </p>
      <p class="mt-1 сaprino opacity-72 font">
        {{ address }}
      </p>
      <div class="mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    img: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    height: 122px;
  }
  &__info {
    padding: 16px;
  }
}
.font {
  font-size: 11px;
}
</style>
