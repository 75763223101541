<template>
  <div class="institution-form">
    <admin-header
      :title="!isEditPage ? 'Новое учреждение' : name"
      :is-save="!isSave && isEditPage"
    />
    <div class="flex mt-8">
      <div class="institution-form__form flex flex-1 flex-column">
        <div class="flex">
          <img-uploader
            class="institution-form__img-cont mr-6"
            @change="onUpload"
            @onViewImage="onViewImage(imageUrl)"
            @deleteFile="deleteFile"
            :src="imageUrl"
          />
          <div class="flex-1">
            <r-input
              @blur="isChange('name')"
              label="Сокращённое наименование"
              v-model="name"
            />
            <r-input
              @blur="isChange('fullName')"
              class="mt-6"
              label="Полное наименование"
              v-model="fullName"
            />
          </div>
        </div>
        <r-textarea
          :rows="4"
          @input="isChange('description')"
          class="mt-6"
          label="Описание"
          v-model="description"
        />

        <div class="flex mt-10">
          <div class="flex-1 mr-2">
            <r-input
              @onPressEnter="addPhone"
              label="Телефон"
              v-model="phone"
            />
            <contact-items
              v-show="phoneList.length"
              class="mt-4"
              v-model="phoneList"
            />
          </div>
          <r-button-simple
            size="larishae"
            type="primary"
            color="rocky"
            :disabled="!phone"
            @click="addPhone"
            icon="add"
          />
        </div>

        <div class="mt-6 flex align-start">
          <div class="flex-1 mr-2">
            <r-input
              label="E-mail"
              @onPressEnter="addMail"
              v-model="mail"
            />
            <contact-items
              v-show="mailList.length"
              class="mt-4"
              icon="mail"
              v-model="mailList"
            />
          </div>
          <r-button-simple
            size="larishae"
            type="primary"
            color="rocky"
            :disabled="!mail"
            @click="addMail"
            icon="add"
          />
        </div>

        <r-textarea
          @input="isChange('workHours')"
          class="mt-6"
          label="График работы"
          v-model="workHours"
        />

        <h2 class="mt-10 taleggio">
          Ссылки
        </h2>
        <r-input
          @blur="isChange('web')"
          class="mt-6"
          label="Сайт"
          v-model="web"
        />
        <r-input
          @blur="isChange('webSchedule')"
          class="mt-6"
          label="Расписание работы"
          v-model="webSchedule"
        />
        <r-input
          @blur="isChange('webEmias')"
          class="mt-6"
          label="Запись к специалистам"
          v-model="webEmias"
        />
      </div>

      <div class="ml-6 flex flex-column flex-1 overflow-hidden">
        <rir-map
          v-if="isShowMap"
          :center="coordinates.length ? coordinates : $cityCenter"
          @click="onMapClick"
          collapse-btn
          style="height: 400px;"
        >
          <ymap-marker
            v-if="coordinates.length"
            :coords="coordinates"
            :marker-id="'marker'"
            :options="{
              hideIconOnBalloonOpen: false,
              draggable: true
            }"
            :icon="$markerIcon"
            @dragend="onDragMarker"
          />
        </rir-map>
        <r-input
          class="mt-4"
          label="Адрес"
          v-model="address"
          @keyup="searchAddress"
        />
        <!-- <SearchList /> -->
      </div>
    </div>

    <div class="mt-10">
      <h2 class="tallegio mb-6">
        Контактная информация
      </h2>

      <div
        v-for="(contact, index) in contacts"
        :key="index"
      >
        <div class="flex mb-4">
          <p class="bryndza">
            {{ contact.type === 'staff' ? 'Сотрудник' : 'Подразделение' }}
          </p>
          <button
            class="ml-4 briscola fargo--text flex align-center delete-btn"
            @click="onRemoveContact(index)"
          >
            <r-icon
              class="mr-2"
              icon="delete"
              fill="fargo"
              size="16"
            />
            Удалить
          </button>
        </div>
        <div class="mb-10 flex">
          <img-uploader
            class="mr-6 rounded-full"
            :src="contact.img"
            @change="onUpload($event, 'staff', index)"
            @onViewImage="onViewImage(contact.img)"
            @deleteFile="deleteFile(contact.type, index)"
          />
          <div class="flex flex-column flex-1">
            <r-input
              @blur="isChange('name')"
              :label="contact.type === 'staff' ? 'ФИО' : 'Наименование'"
              v-model="contact.name"
            />
            <div class="mt-6 flex align-start">
              <div class="flex-1 mr-2">
                <r-input
                  @blur="isChange('name')"
                  label="Телефон"
                  @onPressEnter="
                    contact.phoneList.push(contact.phone);
                    contact.phone = null;
                  "
                  v-model="contact.phone"
                />
                <contact-items
                  v-show="contact.phoneList.length"
                  class="mt-4"
                  v-model="contact.phoneList"
                />
              </div>
              <r-button-simple
                size="larishae"
                type="primary"
                color="rocky"
                :disabled="!contact.phone"
                @click="
                  contact.phoneList.push(contact.phone);
                  contact.phone = null;
                "
                icon="add"
              />
            </div>
          </div>

          <div class="ml-6 flex flex-column flex-1">
            <r-input
              @blur="isChange('name')"
              v-show="contact.type === 'staff'"
              label="Должность"
              v-model="contact.position"
            />
            <div :class="['flex', { 'mt-6': contact.type === 'staff' }]">
              <div class="flex-1 mr-2">
                <r-input
                  @blur="isChange('name')"
                  label="E-mail"
                  @onPressEnter="
                    contact.mailList.push(contact.mail);
                    contact.mail = null;
                  "
                  v-model="contact.mail"
                />
                <contact-items
                  v-show="contact.mailList.length"
                  class="mt-4"
                  icon="mail"
                  v-model="contact.mailList"
                />
              </div>
              <r-button-simple
                size="larishae"
                type="primary"
                color="rocky"
                @click="
                  contact.mailList.push(contact.mail);
                  contact.mail = null;
                "
                icon="add"
                :disabled="!contact.mail"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-10 flex">
        <r-button-action
          title="Контакты сотрудника"
          icon="add"
          :is-arrow="false"
          :disabled="false"
          :not-shorter="false"
          color="rocky"
          size="sulguni"
          @click="addContact('staff')"
        />
        <r-button-action
          class="ml-6"
          title="Контакты подразделения"
          icon="add"
          :is-arrow="false"
          :disabled="false"
          :not-shorter="false"
          color="rocky"
          size="sulguni"
          @click="addContact('unit')"
        />
      </div>
    </div>
    <div
      class="flex align-center custom mt-10 mb-60px pointer fargo-hover"
      @click="DeleteItem"
      v-if="isEditPage"
    >
      <r-icon
        icon="delete"
        fill="fargo"
      />
      <div class="fargo--text ml-2 text-c16">
        Удалить учреждение
      </div>
    </div>
    <div class="institution-form__btn-cont mt-6">
      <r-button
        @click="submit"
        :disabled="isEditPage ? isSave : null"
        width="wide"
        :title="isEditPage ? 'Сохранить' : 'Добавить'"
      />
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
// import SearchList from '@/components/SearchList.vue';
import ContactItems from '@/components/ContactItems.vue';
import ImgUploader from '@/components/ImgUploader.vue';
import RirMap from '@/components/RirMap.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import Api from '../api/Api';
import ModalCrop from '@/components/ModalCrop.vue';
import ModalViewImage from '@/components/ModalViewImage';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';

export default {
  components: {
    ContactItems,
    ImgUploader,
    RirMap,
    AdminHeader
  },
  data() {
    return {
      contactImgLoadId: null,
      phone: null,
      mail: null,
      address: null,
      phoneList: [],
      mailList: [],
      web: null,
      webSchedule: null,
      webEmias: null,
      description: null,
      workHours: null,
      name: null,
      fullName: null,
      imageUrl: null,
      loadedImg: {},
      isLoading: false,
      isShowMap: false,
      contacts: [],
      coordinates: [],
      addressLoading: false,
      contactImg: null,
      initialData: [],
      isSave: true
    };
  },

  computed: {
    isEditPage() {
      return this.$route.query.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    },
    isCanSave() {
      let checkContacts = true;
      this.contacts.forEach(item => {
        checkContacts = !!(item.name && item.position);
      });
      return !!(this.name?.length && this.address?.length && checkContacts);
    }
  },
  watch: {
    contactImg(val) {
      this.contacts[this.contactImgLoadId].img = val;
    }
  },

  activated() {
    this.resetData();
    if (this.isEditPage || this.isCopy) {
      this.setData(this.$route.query.id);
    }
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async mounted() {
    await this.$store.dispatch('getStaff');
    (this.isEditPage || this.isCopy) && this.setData(this.$route.query.id);
  },
  methods: {
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.save,
        title: this.fullName,
        actionPostfix: 'Institution'
      });
    },
    async DeleteItem() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$route.params.id,
        actionPostfix: 'Institution'
      });
    },
    isChange(field) {
      if (this.initialData[field] === this[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.address;
      }

      this.addressLoading = true;

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        this.address = res.address;
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.coordinates = [res.lat, res.lng];
      }

      this.addressLoading = false;
    },
    onViewImage(url) {
      this.$refs.modal.openModal(ModalViewImage, {
        file: url
      });
    },
    deleteFile(type, index) {
      if (type == 'staff') {
        this.contacts[index].img = null;
      } else {
        this.imageUrl = null;
      }
    },
    onUpload(file, target = null, id) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };
      console.log(this.contacts);

      this.$refs.modal.openModal(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imageUrl',
        portrait: target === 'staff'
      });

      this.contactImgLoadId = id;
      this.isChange('loadedImg');
    },
    onRemoveContact(index) {
      this.contacts.splice(index, 1);
    },
    addContact(type = 'staff', data = {}) {
      this.contacts.push({
        type,
        img: null,
        name: null,
        position: null,
        mail: null,
        phone: null,
        mailList: [],
        phoneList: [],
        ...data
      });
    },
    addPhone() {
      this.phoneList.push(this.phone);
      this.phone = null;
      this.isChange('phoneList');
    },
    addMail() {
      this.mailList.push(this.mail);
      this.mail = null;
      this.isChange('mailList');
    },
    async save() {
      const data = {
        id: this.isEditPage ? this.$route.query.id : -1, // Отрицательный id необходим для добавления нового объекта
        name: this.name,
        fullName: this.fullName,
        description: this.description,
        img: this.imageUrl,
        address: this.address,
        phones: this.phoneList,
        emails: this.mailList,
        web: this.web,
        webSchedule: this.webSchedule,
        webEmias: this.webEmias,
        hours: this.workHours,
        coordinates: this.coordinates,
        lat: this.coordinates[0],
        lng: this.coordinates[1],
        contacts: this.contacts.map(el => ({
          img: el.img,
          name: el.name,
          position: el.position,
          mailList: el.mailList,
          phoneList: el.phoneList,
          type: el.type
        }))
      };
      this.isLoading = true;

      await this.$store.dispatch('setInstitution', data);
      await this.$store.dispatch('getClinics');
      this.isLoading = false;
      // this.$router.push({name: 'admin'});
      const r = this.$router.resolve({
        name: 'admin'
      });
      window.location.assign(r.href);
    },
    onDragMarker(e) {
      console.log(e.get('target').geometry._bounds[0]);
      this.coordinates = e.get('target').geometry._bounds[0];
      this.searchAddress(this.coordinates);
    },
    onMapClick(coords) {
      this.coordinates = coords;
      this.searchAddress(this.coordinates);
      //   if (!this.isEditPage) {
      //     this.coordinates = coords;
      //   }
    },
    resetData() {
      this.phone = null;
      this.mail = null;
      this.address = null;
      this.phoneList = [];
      this.mailList = [];
      this.web = null;
      this.webSchedule = null;
      this.webEmias = null;
      this.description = null;
      this.workHours = null;
      this.name = null;
      this.fullName = null;
      this.imageUrl = null;
      this.loadedImg = {};
      this.isLoading = false;
      this.contacts = [];
      this.coordinates = [];
      !this.isEditPage && !this.copy && this.addContact('staff', { position: 'Главный врач' });
    },
    async setData(id) {
      this.resetData();
      if (this.$store.state.clinics?.length < 1) {
        await this.$store.dispatch('getClinics');
      }
      const clinic = this.$store.getters.getInstitutionById(id);
      this.address = clinic?.address || null;
      this.phoneList = clinic?.phones || [];
      this.mailList = clinic?.emails || [];
      this.web = clinic?.web || null;
      this.webSchedule = clinic?.webSchedule || null;
      this.webEmias = clinic?.webEmias || null;
      this.description = clinic.description;
      this.workHours = clinic.hours;
      this.name = clinic.name;
      this.fullName = clinic.fullName;
      this.imageUrl = clinic.img;
      this.loadedImg = clinic.img ? clinic.img : null;
      this.contacts = clinic.contacts || [];
      this.coordinates = [clinic.lat, clinic.lng];
      this.contacts.map((item, i) => (item.id = `id${i}`));
      this.initialData = JSON.parse(JSON.stringify(this.$data));
    }
  }
};
</script>

<style lang="scss" scoped>
.institution-form {
  min-height: 100vh;
  padding-bottom: 130px;

  &__img-cont {
    border-radius: 8px;
  }

  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #f6f9fe;
    z-index: 2;
    padding: 32px 40px 40px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}

.delete-btn {
  opacity: 0.72;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
</style>
