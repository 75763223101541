<template>
  <div class="objects">
    <h1 class="ricotta mb-7">
      Медицина
    </h1>
    <r-tabs
      class="mt-7"
      :items="tabItems"
      v-model="activeTabId"
    />
    <router-link
      class="mt-6 flex align-center sulguni rocky--text"
      :to="{ name: `${activeTabId.id}` }"
    >
      <r-icon
        class="mr-2"
        icon="add"
      />
      <div class="mtop">
        {{ addButtonTitle }}
      </div>
    </router-link>
    <div class="objects__filter">
      <r-select
        v-show="activeTabId.id !== 'institutions'"
        class="flex-1 mr-6"
        select-all
        select-first-item
        :items="objectTypes"
        label="Тип объекта"
        v-model="selectedCategory"
      />

      <!--      <rir-select-->
      <!--        class="flex-1 mr-6"-->
      <!--        :items="sortTypes"-->
      <!--        placeholder="Сортировать"-->
      <!--        v-model="selectedSortType"-->
      <!--      />-->
      <r-input
        class="flex-1"
        v-model="search"
        label="Поиск"
        :is-clear="true"
        before-icon="search"
        size="eluno"
        :is-clear-model-string="true"
      />
      <div
        v-show="activeTabId.id !== 'staff'"
        class="flex ml-6"
      >
        <r-button-simple
          size="larishae"
          icon="icon-view"
          icon-size="20"
          @click="selectedView = 'table'"
          :type="selectedView === 'map' ? 'secondary' : 'primary'"
        />
        <r-button-simple
          size="larishae"
          class="ml-2"
          icon="geopoint"
          icon-size="20"
          :type="selectedView === 'map' ? 'primary' : 'secondary'"
          @click="selectedView = 'map'"
        />
      </div>
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-6">
        <div class="ml-auto parmigiano text-titanic opacity-48">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ filteredObjects.length }}
        </div>
      </div>
      <div
        v-show="activeTabId.id === 'staff' || selectedView === 'table'"
        class="objects__list mt-6"
      >
        <router-link
          v-for="item in filteredObjects"
          :key="item.uid"
          :to="{
            name: activeTabId.id,
            query: { id: item.id }
          }"
        >
          <card
            :uid="item.id"
            :img="item.img"
            :img-border="activeTabId.id === 'staff'"
            :icon="activeTabId.id === 'institutions' ? 'mayor' : 'doc'"
            :title="item.name"
            :address="activeTabId.id === 'staff' ? item.position : item.address"
            :count="item.count"
            :purge-deadline="item.purgeDeadline"
            :current-tab="activeTabId.id"
          >
            <div
              class="flex align-center"
              v-if="item.purgeDeadline"
            >
              <r-icon
                class="mr-2"
                icon="block"
                size="16"
                fill="fargo"
              />
              <span
                class="briscola fargo--text"
                :style="{ color: 'var(--rir-fargo)' }"
              >
                Будет удалено
                {{ formatDate(item.purgeDeadline) }}</span>
            </div>
            <div
              v-else
              class="flex align-cenetr"
            >
              <div
                v-if="activeTabId.id === 'staff'"
                class="flex mr-5"
              >
                <r-icon
                  class="mr-2"
                  icon="link"
                  size="16"
                  :fill="item.counts ? 'rocky' : 'fargo'"
                />
                <span
                  v-if="item.counts"
                  class="briscola rocky--text"
                  :style="!item.counts ? { color: 'var(--rir-fargo)' } : null"
                >Участок {{ item.counts }}</span>
              </div>

              <template v-else>
                <div
                  class="flex mr-5"
                  v-show="activeTabId.id === 'districts'"
                >
                  <r-icon
                    class="mr-2"
                    icon="star"
                    size="16"
                    :fill="item.rating ? 'rocky' : 'fargo'"
                  />
                  <span
                    class="briscola"
                    :style="!item.rating ? { color: 'var(--rir-fargo)' } : null"
                  >{{ item.rating || '-,-' }}</span>
                </div>
                <div
                  class="flex"
                  v-show="activeTabId.id !== 'institutions'"
                >
                  <r-icon
                    class="mr-2"
                    icon="avatar"
                    size="16"
                    :fill="item.counts ? 'rocky' : 'fargo'"
                  />
                  <span
                    class="briscola"
                    :style="!item.counts ? { color: 'var(--rir-fargo)' } : null"
                  >{{ item.counts || 0 }}</span>
                </div>
              </template>
            </div>
          </card>
        </router-link>
      </div>

      <div
        v-if="selectedView === 'map' && activeTabId.id !== 'staff'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          @onRightClick="onMapRightClick"
          @click="onMapClick"
        >
          <div v-if="filteredObjects.length && activeTabId.id === 'institutions'">
            <!-- TODO: Координаты должны быть обязательным параметром -->
            <ymap-marker
              v-for="marker in filteredObjects.filter(el => el.hidden !== '1')"
              :key="`marker_${marker.id}`"
              :coords="[`${marker.lat || 0}`, `${marker.lng || 0}`]"
              :marker-id="`marker_${marker.id}`"
              :icon="$markerIcon()"
              :options="{ hideIconOnBalloonOpen: false }"
              cluster-name="main"
              @click="onMarkerClick(`marker_${marker.id}`)"
              @balloonopen="bindListener"
              @balloonclose="unbindListener"
            >
              <balloon-card
                slot="balloon"
                :title="marker.name"
                :address="marker.address"
                :img="marker.img"
                :data-id="`marker_${marker.id}`"
              >
                <div class="flex mt-3 justify-space-between">
                  <button
                    class="flex opacity-72 pointer"
                    data-action="copy"
                    transparent
                    contentWidth
                  >
                    <r-icon
                      class="mr-2"
                      icon="copy"
                      size="16"
                      fill="rocky"
                    />
                    <div class="briscola rocky--text">
                      Дублировать
                    </div>
                  </button>
                  <button
                    transparent
                    contentWidth
                    data-action="delete"
                  >
                    <r-icon
                      class="opacity-72 pointer"
                      icon="delete"
                      fill="fargo"
                      size="16"
                    />
                  </button>
                </div>
              </balloon-card>
            </ymap-marker>
          </div>

          <div v-if="filteredObjects.length && activeTabId.id === 'districts'">
            <div
              :key="district.id"
              v-for="district in filteredObjects"
            >
              <div
                v-if="countArrays(district.geometry) >= 4"
                v-for="(pol, index) in district.geometry"
                :key="index"
              >
                <div
                  v-for="(pol, index) in district.geometry"
                  :key="`${district.id}_${index}_${searchText}`"
                >
                  <ymap-marker
                    :key="`district_${district.id}_${index}_marker`"
                    :marker-id="`district_${district.id}`"
                    marker-type="Polygon"
                    @click="onMarkerClick(`district_${district.id}`)"
                    @balloonopen="bindListener"
                    @balloonclose="unbindListener"
                    :coords="pol"
                    :hint-content="`Участок №${district.district}`"
                    :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                    :marker-stroke="{ color: '#3d75e4', width: 1 }"
                    :options="{
                      /** Недокументированная опция!
                Задает активную область над кастомной иконкой точки (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorVertexIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      /** Недокументированная опция!
                Задает активную область над промежуточнй точкой (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorEdgeIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      editorVertexLayout: yaMapPoint,
                      editorVertexLayoutHover: yaMapPoint,
                      editorVertexLayoutActive: yaMapPoint,
                      editorVertexLayoutDrag: yaMapPoint,
                      editorEdgeLayout: yaMapPointEdge,
                      editorEdgeLayoutHover: yaMapPointEdge,
                      editorEdgeLayoutActive: yaMapPointEdge,
                      editorEdgeLayoutDrag: yaMapPointEdge
                    }"
                    :icon="$markerIcon()"
                    fill-rule="nonZero"
                  >
                    <balloon-card
                      slot="balloon"
                      :title="district.name"
                      :address="district.address"
                      :img="district.img"
                      :data-id="`district_${district.id}`"
                    >
                      <div class="flex mt-3 justify-space-between">
                        <button
                          class="flex opacity-72 pointer"
                          data-action="copy"
                          transparent
                          contentWidth
                        >
                          <r-icon
                            class="mr-2"
                            icon="copy"
                            size="16"
                            fill="rocky"
                          />
                          <div class="briscola rocky--text">
                            Дублировать
                          </div>
                        </button>
                        <button
                          transparent
                          contentWidth
                          data-action="delete"
                        >
                          <r-icon
                            class="opacity-72 pointer"
                            icon="delete"
                            fill="fargo"
                            size="16"
                          />
                        </button>
                      </div>
                    </balloon-card>
                  </ymap-marker>
                </div>
              </div>
              <div
                v-if="countArrays(district.geometry) == 3"
                v-for="(pol, index) in [district.geometry]"
                :key="index"
              >
                <div
                  v-for="(pol, index) in [district.geometry]"
                  :key="`${district.id}_${index}_${searchText}`"
                >
                  <ymap-marker
                    :key="`district_${district.id}_${index}_marker`"
                    :marker-id="`district_${district.id}`"
                    marker-type="Polygon"
                    @click="onMarkerClick(`district_${district.id}`)"
                    @balloonopen="bindListener"
                    @balloonclose="unbindListener"
                    :coords="pol"
                    :hint-content="`Участок №${district.district}`"
                    :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                    :marker-stroke="{ color: '#3d75e4', width: 1 }"
                    :options="{
                      /** Недокументированная опция!
                Задает активную область над кастомной иконкой точки (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorVertexIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      /** Недокументированная опция!
                Задает активную область над промежуточнй точкой (editorVertexLayout),
                тем самым включает взаимодействие с ней */
                      editorEdgeIconShape: {
                        type: 'Rectangle',
                        coordinates: [
                          [-4, -4],
                          [4, 4]
                        ]
                      },
                      editorVertexLayout: yaMapPoint,
                      editorVertexLayoutHover: yaMapPoint,
                      editorVertexLayoutActive: yaMapPoint,
                      editorVertexLayoutDrag: yaMapPoint,
                      editorEdgeLayout: yaMapPointEdge,
                      editorEdgeLayoutHover: yaMapPointEdge,
                      editorEdgeLayoutActive: yaMapPointEdge,
                      editorEdgeLayoutDrag: yaMapPointEdge
                    }"
                    :icon="$markerIcon()"
                    fill-rule="nonZero"
                  >
                    <balloon-card
                      slot="balloon"
                      :title="district.name"
                      :address="district.address"
                      :img="district.img"
                      :data-id="`district_${district.id}`"
                    >
                      <div class="flex mt-3 justify-space-between">
                        <button
                          class="flex opacity-72 pointer"
                          data-action="copy"
                          transparent
                          contentWidth
                        >
                          <r-icon
                            class="mr-2"
                            icon="copy"
                            size="16"
                            fill="rocky"
                          />
                          <div class="briscola rocky--text">
                            Дублировать
                          </div>
                        </button>
                        <button
                          transparent
                          contentWidth
                          data-action="delete"
                        >
                          <r-icon
                            class="opacity-72 pointer"
                            icon="delete"
                            fill="fargo"
                            size="16"
                          />
                        </button>
                      </div>
                    </balloon-card>
                  </ymap-marker>
                </div>
              </div>
            </div>
          </div>
        </rir-map>
      </div>
      <not-found v-show="filteredObjects.length < 1" />
    </template>
  </div>
</template>

<script>
import Card from '@/components/AdminCard.vue';
import RirMap from '@/components/RirMap.vue';
import BalloonCard from '@/components/BalloonCard.vue';
import { loadYmap } from '../vue-yandex-maps/vue-yandex-maps.umd';
import notFound from '../components/notFound';
import Loader from '@/components/Loader.vue';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    Card,
    RirMap,
    BalloonCard,
    loadYmap,
    notFound
  },
  data() {
    return {
      selectedView: 'table',
      selectedCategory: null,
      selectedSortType: 1,
      activeTabId: {
        id: 'institutions',
        title: 'Учреждения'
      },
      countOnPublic: null,
      countOnModerate: null,
      searchText: null,
      timeout: null,
      selectedMarkerId: null,
      yamap: {},
      yaMapPoint: null,
      yaMapPointEdge: null,
      drawTarget: null,
      isShowMap: false
    };
  },
  computed: {
    sortTypes() {
      if (this.activeTabId.id !== 'staff') {
        this.selectedSortType = 1;
        return [
          {
            id: 1,
            value: 'По названию'
          },
          {
            id: 2,
            value: 'По рейтингу'
          }
        ];
      }
      this.selectedSortType = 3;
      return [
        {
          id: 3,
          value: 'По ФИО'
        },
        {
          id: 4,
          value: 'По участку'
        }
      ];

      return types;
    },
    addButtonTitle() {
      if (this.activeTabId.id === 'institutions') {
        return 'Добавить учреждение';
      } if (this.activeTabId.id === 'districts') {
        return 'Добавить участок';
      }
      return 'Добавить врача';
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },

    filteredObjects() {
      const objects = [];
      if (this.activeTabId.id === 'institutions') {
        objects.push(
          ...this.$store.state.clinics.map(el => ({
            ...el,
            counts: el?.contacts?.length || null,
            name: el?.name || '',
            address: el?.address || ''
            // rating: el.rating
          }))
        );
      } else if (this.activeTabId.id === 'districts') {
        const districts = this.$store.state.districts.map(el => ({
          ...el,
          geometry: Array.isArray(el.geometry) && el.geometry.length ? el.geometry : [],
          img: el?.clinics?.[0].img || '',
          address: el?.clinics?.[0].address || '',
          name: el.district ? `Участок №${el.district}` : '-',
          counts: el?.staff?.length,
          // TODO:fix у текущих данных кривые данные в адрес
          rating: el.rating
        }));
        if (this.selectedCategory) {
          objects.push(...districts.filter(el => +el.type === +this.selectedCategory));
        } else {
          objects.push(...districts);
        }
      } else if (this.activeTabId.id === 'staff') {
        const staff = this.$store.state.staff.map(el => ({
          ...el,
          // TODO:fix у текущих данных кривые данные в адрес
          name: el.name || '',
          position: el.position || '-',
          counts: el?.districts?.length ? el.districts.join(', ') : null,
          // counts: { staff: el?.staff | null },
          rating: el.rating
        }));

        if (this.selectedCategory) {
          objects.push(...staff.filter(el => +el.type === +this.selectedCategory));
        } else {
          objects.push(...staff);
        }
      }

      if (this.searchText) {
        return objects.filter(
          el => el.name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0
        );
      }

      return objects;
    },

    objectTypes() {
      if (
        ['staff', 'districts'].includes(this.activeTabId.id)
        && this.$store.state.types?.doctorTypesClean?.length > 0
      ) {
        return this.$store.state.types?.doctorTypesClean.map(el => ({ id: el.Id, title: el.Name }));
      }

      return [];
    },
    isLoading() {
      return this.$store.getters.getLoading;
    },

    tabItems() {
      const tabs = [
        {
          id: 'institutions',
          title: 'Учреждения'
          // count: this.countOnPublic
        },
        {
          id: 'districts',
          title: 'Участки'
          // count: this.countOnModerate,
          // countColor: 'fargo'
        },
        {
          id: 'staff',
          title: 'Врачи'
          // count: this.countOnModerate,
          // countColor: 'fargo'
        }
      ];
      return tabs;
    }
  },

  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
    this.unbindListener();
  },

  async mounted() {
    await this.$store.dispatch('getDistricts');
    await this.$store.dispatch('getClinics');
    await this.$store.dispatch('loadTypes');
    await this.$store.dispatch('getStaff');
    const settings = { lang: 'ru_Ru' };
    !window.ymaps && (await loadYmap(settings));
    // console.log('ymaps', ymaps); // здесь доступен весь функционал ymap

    this.yaMapPoint = ymaps.templateLayoutFactory.createClass("<div class='ya-map-point'></div>");
    this.yaMapPointEdge = ymaps.templateLayoutFactory.createClass(
      "<div class='ya-map-point--edge'></div>"
    );
  },
  methods: {
    countArrays(array) {
      let sumTotal = 1;

      for (const element of array) {
        if (Array.isArray(element)) {
          sumTotal += this.countArrays(element);
        }
      }

      return sumTotal;
    },
    setCurrentMarker(e) {
      console.log('setCurrentMarker', e);
    },

    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    onMapRightClick(e) {
      console.log('rightClick', e);
      this.drawTarget && this.drawTarget.stopEditing();
    },
    onMapClick(e) {
      console.log('mapClick', e);
      this.selectedMarkerId = null;
    },

    onBalloonClick(e) {
      console.log('onBalloonClick', e);
      const closest = e.target.closest('button');
      const id = this.selectedMarkerId.split('_')[1];

      if (!closest) {
        this.$router.push({
          name: this.activeTabId.id,
          query: { id }
        });
        return;
      }

      if (closest.getAttribute('data-action') === 'copy') {
        this.$router.push({
          name: this.activeTabId.id,
          query: { id, copy: true }
        });
      } else if (closest.getAttribute('data-action') === 'delete') {
        this.onDelete();
      }
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },

    async onDelete() {
      console.log('delete', this.selectedMarkerId);

      const id = this.selectedMarkerId.split('_')[1];

      let actionName = null;
      let actionNameGET = null;
      if (this.activeTabId.id === 'districts') {
        actionName = 'deleteDistrict';
        actionNameGET = 'getDistrict';
      } else if (this.activeTabId.id === 'staff') {
        actionName = 'deleteStaff';
        actionNameGET = 'getStaff';
      } else if (this.activeTabId.id === 'institutions') {
        actionName = 'deleteInstitution';
        actionNameGET = 'getInstitution';
      }
      if (actionName) {
        const res = await this.$store.dispatch(actionName, id);
        res && this.$store.dispatch(actionNameGET);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString('Ru-ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}
.justify-space-between {
  justify-content: space-between;
}
</style>
