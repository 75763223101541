import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';

const api = new Api();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: null,
    districts: [],
    clinics: [],
    staff: [],
    selectedObject: null,
    isStaffLoading: true,
    isClinicLoading: true,
    isDistrictLoading: true,
    user: null
  },
  mutations: {
    setTypes(state, types) {
      state.types = types;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setDistricts(state, data) {
      state.districts = data;
    },
    setClinics(state, data) {
      state.clinics = data;
    },
    setStaff(state, data) {
      state.staff = data;
    },
    setSelectedObject(state, object) {
      state.selectedObject = object;
    },
    setStaffLoading(state, status) {
      state.isStaffLoading = status;
    },
    setClinicLoading(state, status) {
      state.isClinicLoading = status;
    },
    setDistrictLoading(state, status) {
      state.isDistrictLoading = status;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  getters: {
    getLoading: state => state.isStaffLoading || state.isClinicLoading || state.isDistrictLoading,
    getUser: state => state.user,
    getTypes: state => state.objects.types,
    getDocTypes: state => state.types?.doctorTypesClean || [],
    getInstitutions: state => state.objects.types.clinics,
    getInstitutByType: state => type => state.clinics.filter(el => String(el.type) === String(type)),
    getStaffByType: state => type => state.staff.filter(el => String(el.type) === String(type)),
    getObjectById: state => id => state.objects.find(el => el.id === id),
    getStaffById: state => id => state.staff.find(el => String(el.id) === String(id)),
    getDistrictById: state => id => state.districts.find(el => String(el.id) === String(id)),
    getInstitutionById: state => id => state.clinics.find(el => String(el.id) === String(id))
  },
  actions: {
    async getUserInfo(ctx) {
      const res = await api.getUserInfo();
      console.log('userInfo', res);
      ctx.commit('setUser', res);
    },
    async selectObject(ctx, id) {
      if (!ctx.state.objects?.length) {
        const res = await api.getList({ readOnly: 1, type: 0, currentMainMenuNumber: 5 });
        ctx.commit('setObjects', res.all);
      }
      const selected = ctx.state.objects.find(el => el.id === id);
      ctx.commit('setSelectedObject', selected);
    },

    async getClinics({ commit }) {
      commit('setClinicLoading', true);
      const clinics = await api.getClinics();
      commit('setClinics', clinics.all);
      commit('setClinicLoading', false);
      console.log('clinics', clinics);
    },
    async getDistricts({ commit }) {
      commit('setDistrictLoading', true);
      const districts = await api.getDoctorHouses();
      commit('setDistricts', districts.all);
      commit('setDistrictLoading', false);
      console.log('districts', districts);
    },
    async getStaff({ commit }) {
      commit('setStaffLoading', true);
      const res = await api.getStaff();
      commit('setStaffLoading', false);
      commit('setStaff', res.all);
    },
    async loadTypes(ctx) {
      const res = await api.getTypes();
      console.log('types', res);
      ctx.commit('setTypes', res);
    },
    async setStaff({ commit, dispatch }, data) {
      commit('setStaffLoading', true);
      const res = await api.setStaff({ item: data });
      res?.error === '' && dispatch('getStaff');
      commit('setStaffLoading', false);
      console.log('addStaff', res);
    },
    async setDistrict({ commit, dispatch }, data) {
      commit('setDistrictLoading', true);
      const res = await api.setDistrict({ item: data });
      res?.error === '' && dispatch('getDistricts');
      commit('setDistrictLoading', false);
      console.log('addDistrict', res);
    },
    async setInstitution({ commit, dispatch }, data) {
      commit('setClinicLoading', true);
      const res = await api.setClinic({ item: data });
      res?.error === '' && dispatch('getClinics');
      commit('setClinicLoading', false);
      console.log('addClinic', res);
    },
    async deleteDistrict({ commit, dispatch }, id) {
      const res = await api.deleteDistrict({ item: { id } });
      res?.error === '' && dispatch('getDistricts');
    },
    async deleteInstitution({ commit, dispatch }, id) {
      commit('setClinicLoading', true);
      const res = await api.deleteClinic({ item: { id } });
      res?.error === '' ? dispatch('getClinics') : commit('setClinicLoading', false);
      console.log('deleteInstitution', res);
    },
    async deleteStaff({ commit, dispatch }, id) {
      commit('setStaffLoading', true);
      const res = await api.deleteStaff({ item: { id } });
      res?.error === '' ? dispatch('getStaff') : commit('setClinicLoading', false);
      console.log('deleteStaff', res);
    }
  },
  modules: {}
});
