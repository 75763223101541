<template>
  <div
    class="ml-auto mr-auto"
    style="display:block;"
  >
    <img
      class="modal-view-image__view"
      :style="{
        aspectRatio: ratio
      }"
      :src="file"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: 'ModalViewImage',
  props: {
    file: {
      type: Object
    },
    ratio: {
      type: String,
      default: '16/10'
    }
  },
  mounted() {
    window.onpopstate = function (events) {
      const event = new Event('click');
      const dropdownEl = document.querySelector('.rir-modal__content-rir-icon_close');
      if (dropdownEl) {
        dropdownEl.addEventListener('click', () => function (es) {
        });
        dropdownEl.dispatchEvent(event);
      }
    };
  }
};
</script>

<style lang="scss">
.modal-view-image {
  &__view {
    padding: 0 40px;
    max-height: 1000px;
    width: 100%;
    max-width: 1000px;
    height: auto;
  }
}
</style>
