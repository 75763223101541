!(function(t, e) {
  'object' == typeof exports && 'undefined' != typeof module
    ? e(exports, require('vue'))
    : 'function' == typeof define && define.amd
    ? define(['exports', 'vue'], e)
    : e(((t = t || self)['vue-yandex-maps'] = {}), t.vue);
})(this, function(t, o) {
  'use strict';
  function i(t) {
    return (i =
      'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
        ? function(t) {
            return typeof t;
          }
        : function(t) {
            return t &&
              'function' == typeof Symbol &&
              t.constructor === Symbol &&
              t !== Symbol.prototype
              ? 'symbol'
              : typeof t;
          })(t);
  }
  function n(t, e) {
    for (var o = 0; o < e.length; o++) {
      var n = e[o];
      (n.enumerable = n.enumerable || !1),
        (n.configurable = !0),
        'value' in n && (n.writable = !0),
        Object.defineProperty(t, n.key, n);
    }
  }
  function r(t) {
    return (
      (function(t) {
        if (Array.isArray(t)) {
          for (var e = 0, o = new Array(t.length); e < t.length; e++) o[e] = t[e];
          return o;
        }
      })(t) ||
      (function(t) {
        if (
          Symbol.iterator in Object(t) ||
          '[object Arguments]' === Object.prototype.toString.call(t)
        )
          return Array.from(t);
      })(t) ||
      (function() {
        throw new TypeError('Invalid attempt to spread non-iterable instance');
      })()
    );
  }
  function a(t, e) {
    var l = e.options,
      u = e.callbacks,
      p = e.map,
      m = e.useObjectManager,
      d = e.objectManagerClusterize,
      f = e.useHtmlInLayout
        ? '\n    <div v-html="properties.balloonContentHeader"></div>\n    <div v-html="properties.balloonContentBody"></div>\n    <div v-html="properties.balloonContentFooter"></div>\n  '
        : '\n    <div>{{ properties.balloonContentHeader }}</div>\n    <div>{{ properties.balloonContentBody }}</div>\n    <div>{{ properties.balloonContentFooter }}</div>\n  ',
      h = {},
      o = [];
    if (
      (t.forEach(function(t) {
        t.clusterName
          ? (h[t.clusterName] = h[t.clusterName] ? [].concat(r(h[t.clusterName]), [t]) : [t])
          : o.push(t);
      }),
      Object.keys(h).forEach(function(t) {
        var e = l[t] || {},
          o = u[t] || {},
          n = e.layout || f;
        e.clusterBalloonItemContentLayout = ymaps.templateLayoutFactory.createClass(n);
        var r = e.clusterBalloonLayout || e.clusterLayout;
        delete e.clusterBalloonLayout;
        var a = r
          ? ymaps.templateLayoutFactory.createClass(r)
          : e.clusterBalloonContentLayout || 'cluster#balloonTwoColumns';
        e.clusterBalloonContentLayout = a;
        var i = e.clusterIconContentLayout;
        if (((e.clusterIconContentLayout = i && ymaps.templateLayoutFactory.createClass(i)), m)) {
          var s = new ymaps.ObjectManager(Object.assign({ clusterize: d }, e));
          Object.keys(o).forEach(function(t) {
            s.clusters.events.add(t, o[t]);
          }),
            s.add(h[t]),
            p.geoObjects.add(s);
        } else {
          var c = new ymaps.Clusterer(e);
          Object.keys(o).forEach(function(t) {
            c.events.add(t, o[t]);
          }),
            e.createCluster && (c.createCluster = e.createCluster),
            c.add(h[t]),
            p.geoObjects.add(c);
        }
      }),
      o.length)
    ) {
      var n = m ? new ymaps.ObjectManager({ clusterize: !1 }) : new ymaps.GeoObjectCollection();
      o.forEach(function(t) {
        return n.add(t);
      }),
        p.geoObjects.add(n);
    }
  }
  function m(t) {
    return t.charAt(0).toUpperCase() + t.slice(1);
  }
  function s(t, e) {
    var a = [];
    return (function t(e, o) {
      if (e === o) return !0;
      if (e instanceof Date && o instanceof Date) return +e == +o;
      if ('object' !== i(e) || 'object' !== i(o)) return !1;
      if (
        (function(t, e) {
          for (var o = a.length; o--; )
            if (!((a[o][0] !== t && a[o][0] !== e) || (a[o][1] !== e && a[o][1] !== t))) return !0;
          return !1;
        })(e, o)
      )
        return !0;
      a.push([e, o]);
      var n = Object.keys(e),
        r = n.length;
      if (Object.keys(o).length !== r) return !1;
      for (; r--; ) if (!t(e[n[r]], o[n[r]])) return !1;
      return !0;
    })(t, e);
  }
  var g = new ((function() {
      function t() {
        !(function(t, e) {
          if (!(t instanceof e)) throw new TypeError('Cannot call a class as a function');
        })(this, t),
          (this.events = {}),
          (this.ymapReady = !1),
          (this.scriptIsNotAttached = !0);
      }
      return (
        (function(t, e, o) {
          e && n(t.prototype, e), o && n(t, o);
        })(t, [
          {
            key: '$on',
            value: function(t, e) {
              var o = this;
              return (
                this.events[t] || (this.events[t] = []),
                this.events[t].push(e),
                function() {
                  o.events[t] = o.events[t].filter(function(t) {
                    return e !== t;
                  });
                }
              );
            }
          },
          {
            key: '$emit',
            value: function(t, e) {
              var o = this.events[t];
              o &&
                o.forEach(function(t) {
                  return t(e);
                });
            }
          }
        ]),
        t
      );
    })())(),
    e = [
      'fullscreenControl',
      'geolocationControl',
      'routeEditor',
      'rulerControl',
      'searchControl',
      'trafficControl',
      'typeSelector',
      'zoomControl',
      'routeButtonControl',
      'routePanelControl'
    ];
  function c(t) {
    return (
      0 ===
      t.filter(function(t) {
        return ![].concat(e, ['default']).includes(t);
      }).length
    );
  }
  function l() {
    var v = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {};
    return new Promise(function(t, e) {
      if (window.ymaps) return t();
      if (document.getElementById('vue-yandex-maps')) g.$on('scriptIsLoaded', t);
      else {
        var o = document.createElement('SCRIPT'),
          n = v.apiKey,
          r = void 0 === n ? '' : n,
          a = v.lang,
          i = void 0 === a ? 'ru_RU' : a,
          s = v.version,
          c = void 0 === s ? '2.1' : s,
          l = v.coordorder,
          u = void 0 === l ? 'latlong' : l,
          p = v.debug,
          m = void 0 !== p && p,
          d = v.enterprise,
          f = void 0 !== d && d,
          h = m ? 'debug' : 'release',
          y = 'lang='
            .concat(i)
            .concat(r && '&apikey='.concat(r), '&mode=')
            .concat(h, '&coordorder=')
            .concat(u),
          b = 'https://'
            .concat(f ? 'enterprise.' : '', 'api-maps.yandex.ru/')
            .concat(c, '/?')
            .concat(y);
        o.setAttribute('src', b),
          o.setAttribute('async', ''),
          o.setAttribute('defer', ''),
          o.setAttribute('id', 'vue-yandex-maps'),
          document.head.appendChild(o),
          (g.scriptIsNotAttached = !1),
          (o.onload = function() {
            ymaps.ready(function() {
              (g.ymapReady = !0), g.$emit('scriptIsLoaded'), t();
            });
          }),
          (o.onerror = e);
      }
    });
  }
  var u,
    p = 1;
  var d = g,
    f = [
      'actionend',
      'balloonclose',
      'balloonopen',
      'click',
      'contextmenu',
      'dblclick',
      'destroy',
      'hintclose',
      'hintopen',
      'optionschange',
      'sizechange',
      'typechange'
    ],
    h = {
      pluginOptions: {},
      provide: function() {
        var e,
          r,
          t,
          a = this,
          o = [],
          i = [];
        return (
          null != this.balloonComponent &&
            (t = (function(n) {
              var r = null,
                a = 'vue-balloon-'.concat(p);
              return (
                (p += 1),
                function(t, e) {
                  var o = ymaps.templateLayoutFactory.createClass(
                    '<div id="'.concat(a, '"><div>'),
                    {
                      build: function() {
                        o.superclass.build.call(this),
                          (r = new u({
                            parent: t.$root,
                            data: function() {
                              return { props: t.$props, listeners: t.$listeners };
                            },
                            propsData: { marker: e, component: n }
                          })).$mount('#'.concat(a));
                      },
                      clear: function() {
                        r.$destroy(), (r = null), o.superclass.clear.call(this);
                      }
                    }
                  );
                  return o;
                }
              );
            })(this.balloonComponent)),
          {
            useObjectManager: this.useObjectManager,
            addMarker: this.addMarker,
            deleteMarker: function(t) {
              a.$options.static.myMap.geoObjects &&
                (o.push(t),
                e && clearTimeout(e),
                (e = setTimeout(function() {
                  a.deleteMarkers(o), (o = []);
                }, 0)));
            },
            compareValues: function(t) {
              var e = t.newVal,
                o = t.oldVal,
                n = t.marker;
              s(e, o) ||
                (i.push(n),
                r && clearTimeout(r),
                (r = setTimeout(function() {
                  a.setMarkers(i), (i = []);
                }, 0)));
            },
            makeComponentBalloonTemplate: t
          }
        );
      },
      data: function() {
        return {
          ymapId: 'yandexMap'.concat(Math.round(1e5 * Math.random())),
          style: this.ymapClass ? '' : 'width: 100%; height: 100%;',
          isReady: !1,
          debounce: null
        };
      },
      static: { myMap: {}, markers: [] },
      props: {
        coords: { type: Array, required: !0 },
        zoom: {
          validator: function(t) {
            return !Number.isNaN(t);
          },
          default: 18
        },
        bounds: Array,
        clusterOptions: {
          type: Object,
          default: function() {
            return {};
          }
        },
        clusterCallbacks: {
          type: Object,
          default: function() {
            return {};
          }
        },
        behaviors: {
          type: Array,
          default: function() {
            return ['default'];
          }
        },
        controls: {
          type: Array,
          default: function() {
            return ['default'];
          },
          validator: function(t) {
            return c(t);
          }
        },
        detailedControls: {
          type: Object,
          validator: function(t) {
            return c(Object.keys(t));
          }
        },
        scrollZoom: { type: Boolean, default: !0 },
        mapType: {
          type: String,
          default: 'map',
          validator: function(t) {
            return ['map', 'satellite', 'hybrid'].includes(t);
          }
        },
        placemarks: {
          type: Array,
          default: function() {
            return [];
          }
        },
        useObjectManager: { type: Boolean, default: !1 },
        objectManagerClusterize: { type: Boolean, default: !0 },
        ymapClass: String,
        initWithoutMarkers: { type: Boolean, default: !0 },
        debug: { type: Boolean, default: !1 },
        settings: {
          type: Object,
          default: function() {
            return {};
          }
        },
        options: {
          type: Object,
          default: function() {
            return {};
          }
        },
        mapEvents: {
          type: Array,
          default: function() {
            return [];
          }
        },
        showAllMarkers: Boolean,
        disablePan: Boolean,
        balloonComponent: {
          type: [Object, Function],
          default: function() {
            return null;
          }
        },
        useHtmlInLayout: Boolean
      },
      computed: {
        coordinates: function() {
          return this.coords.map(function(t) {
            return +t;
          });
        }
      },
      methods: {
        init: function() {
          var a = this;
          if (
            window.ymaps &&
            ymaps.GeoObjectCollection &&
            (this.initWithoutMarkers || this.$slots.default || this.placemarks.length)
          ) {
            if (
              (this.$emit('map-initialization-started'),
              (this.$options.static.myMap = new ymaps.Map(
                this.ymapId,
                {
                  center: this.coordinates,
                  zoom: +this.zoom,
                  bounds: this.bounds,
                  behaviors: this.behaviors,
                  controls: this.controls,
                  type: 'yandex#'.concat(this.mapType)
                },
                this.options
              )),
              (this.mapEvents.length ? this.mapEvents : f).forEach(function(e) {
                return a.$options.static.myMap.events.add(e, function(t) {
                  return a.$emit(e, t);
                });
              }),
              this.$options.static.myMap.events.add('boundschange', function(t) {
                var e = t.originalEvent,
                  o = e.newZoom,
                  n = e.newCenter,
                  r = e.newBounds;
                a.$emit('boundschange', t),
                  a.$emit('update:zoom', o),
                  a.$emit('update:coords', n),
                  a.$emit('update:bounds', r);
              }),
              this.detailedControls)
            )
              Object.keys(this.detailedControls).forEach(function(t) {
                a.$options.static.myMap.controls.remove(t),
                  a.$options.static.myMap.controls.add(t, a.detailedControls[t]);
              });
            !1 === this.scrollZoom && this.$options.static.myMap.behaviors.disable('scrollZoom'),
              (this.isReady = !0),
              this.$emit('map-was-initialized', this.$options.static.myMap);
          }
        },
        addMarker: function(t) {
          var e = this;
          this.$options.static.markers.push(t),
            this.debounce && clearTimeout(this.debounce),
            (this.debounce = setTimeout(function() {
              e.setMarkers(e.$options.static.markers);
            }, 0));
        },
        setMarkers: function(t) {
          var e = this,
            o = {
              options: this.clusterOptions,
              callbacks: this.clusterCallbacks,
              map: this.$options.static.myMap,
              useObjectManager: this.useObjectManager,
              objectManagerClusterize: this.objectManagerClusterize,
              useHtmlInLayout: this.useHtmlInLayout
            };
          if (this.$options.static.markers !== t) {
            var n = t.map(function(t) {
              return e.useObjectManager ? t.id : t.properties.get('markerId');
            });
            this.deleteMarkers(n),
              a(t, o),
              this.$emit('markers-was-change', n),
              console.log('markers-was-change', n);
          } else a(t, o), this.$emit('markers-was-add', t), console.log('markers-was-add', t);
          (this.$options.static.markers = []),
            this.showAllMarkers &&
              this.$options.static.myMap.setBounds(
                this.$options.static.myMap.geoObjects.getBounds()
              );
        },
        deleteMarkers: function(a) {
          var i = this,
            e = [];
          this.$options.static.myMap.geoObjects.each(function(t) {
            return e.push(t);
          }),
            e.forEach(function(e) {
              var o = [];
              if (i.useObjectManager) e.remove(a);
              else {
                var t,
                  n = function(t) {
                    var e = t.properties.get('markerId');
                    a.includes(e) && o.push(t);
                  };
                if (e.each) e.each(n), (t = e.getLength());
                else if (e.getGeoObjects) {
                  var r = e.getGeoObjects();
                  r.forEach(n), (t = r.length);
                }
                0 === t || t === o.length
                  ? i.$options.static.myMap.geoObjects.remove(e)
                  : o.length &&
                    o.forEach(function(t) {
                      return e.remove(t);
                    });
              }
            }),
            this.$emit('markers-was-delete', a);
        }
      },
      watch: {
        coordinates: function(t) {
          this.disablePan
            ? this.$options.static.myMap.setCenter && this.$options.static.myMap.setCenter(t)
            : this.$options.static.myMap.panTo &&
              this.$options.static.myMap.getZoom() &&
              this.$options.static.myMap.panTo(t, { checkZoomRange: !0 });
        },
        zoom: function() {
          this.$options.static.myMap.setZoom(this.zoom);
        },
        bounds: function(t) {
          this.$options.static.myMap.setBounds && this.$options.static.myMap.setBounds(t);
        }
      },
      render: function(t) {
        var e = 'function' == typeof t ? t : o.h;
        return e('section', { class: 'ymap-container', ref: 'mapContainer' }, [
          e(
            'div',
            'function' == typeof t
              ? { attrs: { id: this.ymapId, class: this.ymapClass, style: this.style } }
              : { id: this.ymapId, class: this.ymapClass, style: this.style }
          ),
          this.isReady &&
            e('div', [
              'function' == typeof this.$slots.default ? this.$slots.default() : this.$slots.default
            ])
        ]);
      },
      mounted: function() {
        var t = this;
        if (this.$attrs['map-link'] || this.$attrs.mapLink)
          throw new Error('Vue-yandex-maps: Attribute mapLink is not supported. Use settings.');
        if (this.placemarks && this.placemarks.length)
          throw new Error(
            'Vue-yandex-maps: Attribute placemarks is not supported. Use marker component.'
          );
        this.mapObserver = new MutationObserver(function() {
          t.$options.static.myMap.container && t.$options.static.myMap.container.fitToViewport();
        });
        var e = this.$refs.mapContainer;
        if (
          (this.mapObserver.observe(e, {
            attributes: !0,
            childList: !0,
            characterData: !0,
            subtree: !1
          }),
          d.scriptIsNotAttached)
        ) {
          var o = this.debug;
          l(
            (function(r) {
              for (var t = 1; t < arguments.length; t++) {
                var a = null != arguments[t] ? arguments[t] : {},
                  e = Object.keys(a);
                'function' == typeof Object.getOwnPropertySymbols &&
                  (e = e.concat(
                    Object.getOwnPropertySymbols(a).filter(function(t) {
                      return Object.getOwnPropertyDescriptor(a, t).enumerable;
                    })
                  )),
                  e.forEach(function(t) {
                    var e, o, n;
                    (e = r),
                      (n = a[(o = t)]),
                      o in e
                        ? Object.defineProperty(e, o, {
                            value: n,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0
                          })
                        : (e[o] = n);
                  });
              }
              return r;
            })({}, this.$options.pluginOptions, this.settings, { debug: o })
          );
        }
        d.ymapReady ? ymaps.ready(this.init) : d.$on('scriptIsLoaded', this.init);
      },
      beforeDestroy: function() {
        this.$options.static.myMap.geoObjects && this.$options.static.myMap.geoObjects.removeAll();
      },
      beforeUnmount: function() {
        this.$options.static.myMap.geoObjects && this.$options.static.myMap.geoObjects.removeAll();
      }
    },
    y = ['placemark', 'polyline', 'rectangle', 'polygon', 'circle'],
    b = [
      'balloonclose',
      'balloonopen',
      'click',
      'contextmenu',
      'dblclick',
      'drag',
      'dragend',
      'dragstart',
      'hintclose',
      'hintopen',
      'mouseenter',
      'mouseleave',
      'geometrychange',
      'overlaychange'
    ],
    v = {
      inject: [
        'useObjectManager',
        'addMarker',
        'deleteMarker',
        'compareValues',
        'makeComponentBalloonTemplate'
      ],
      props: {
        coords: Array,
        hintContent: String,
        icon: Object,
        balloon: Object,
        markerType: {
          type: String,
          validator: function(t) {
            return y.includes(t.toLowerCase());
          },
          default: 'placemark'
        },
        markerFill: Object,
        markerStroke: Object,
        clusterName: [String, Number],
        circleRadius: {
          validator: function(t) {
            return !Number.isNaN(t);
          },
          default: 1e3
        },
        balloonTemplate: String,
        markerId: { type: [String, Number], required: !0 },
        properties: Object,
        options: Object,
        balloonComponentProps: {
          type: Object,
          default: function() {
            return {};
          }
        },
        markerEvents: {
          type: Array,
          default: function() {
            return [];
          }
        }
      },
      data: function() {
        return { unwatchArr: [] };
      },
      render: function(t) {
        var e = 'function' == typeof t ? t : o.h;
        return (
          this.$slots.balloon &&
          e('div', { style: 'display: none;' }, [
            'function' == typeof this.$slots.balloon ? this.$slots.balloon() : this.$slots.balloon
          ])
        );
      },
      mounted: function() {
        var o = this;
        Object.keys(this.$props).forEach(function(t) {
          'balloonComponentProps' !== t &&
            o.unwatchArr.push(
              o.$watch(t, function(t, e) {
                return o.compareValues({ newVal: t, oldVal: e, marker: o.defineMarker() });
              })
            );
        }),
          this.addMarker(this.defineMarker());
      },
      methods: {
        defineMarker: function() {
          var o = this,
            t = {
              markerId: this.markerId,
              markerType: this.markerType || 'placemark',
              coords: (function e(t) {
                return t.map(function(t) {
                  return Array.isArray(t) ? e(t) : +t;
                });
              })(this.coords),
              hintContent: this.hintContent,
              markerFill: this.markerFill,
              circleRadius: +this.circleRadius,
              clusterName: this.clusterName,
              markerStroke: this.markerStroke,
              balloon: this.balloon,
              properties: this.properties,
              options: this.options,
              balloonOptions: {}
            },
            e = null;
          this.balloonTemplate &&
            (e = ymaps.templateLayoutFactory.createClass(this.balloonTemplate)),
            this.$slots.balloon &&
              (e = ymaps.templateLayoutFactory.createClass(
                'function' == typeof this.$slots.balloon
                  ? this.$slots.balloon()[0].elm.outerHTML
                  : this.$slots.balloon[0].elm.outerHTML
              )),
            this.makeComponentBalloonTemplate && (e = this.makeComponentBalloonTemplate(this, t)),
            null != e && (t.balloonOptions.balloonContentLayout = e),
            this.icon && ['default#image', 'default#imageWithContent'].includes(this.icon.layout)
              ? ((t.iconContent = this.icon.content),
                (t.iconLayout = this.icon.layout),
                (t.iconImageHref = this.icon.imageHref),
                (t.iconImageSize = this.icon.imageSize),
                (t.iconImageOffset = this.icon.imageOffset),
                (t.iconContentOffset = this.icon.contentOffset),
                this.icon.contentLayout &&
                  'string' == typeof this.icon.contentLayout &&
                  (t.iconContentLayout = ymaps.templateLayoutFactory.createClass(
                    this.icon.contentLayout
                  )))
              : (t.icon = this.icon);
          var n = (function(t, e) {
              var o = m(t);
              if (!e) return o;
              switch (o) {
                case 'Placemark':
                  return 'Point';
                case 'Polyline':
                  return 'LineString';
                default:
                  return o;
              }
            })(t.markerType, this.useObjectManager),
            r = {
              hintContent: t.hintContent,
              iconContent: t.icon ? t.icon.content : t.iconContent,
              markerId: t.markerId
            },
            a = t.balloon
              ? {
                  balloonContentHeader: t.balloon.header,
                  balloonContentBody: t.balloon.body,
                  balloonContentFooter: t.balloon.footer
                }
              : {},
            i = Object.assign(r, a, t.properties),
            s = t.iconLayout
              ? {
                  iconLayout: t.iconLayout,
                  iconImageHref: t.iconImageHref,
                  iconImageSize: t.iconImageSize,
                  iconImageOffset: t.iconImageOffset,
                  iconContentOffset: t.iconContentOffset,
                  iconContentLayout: t.iconContentLayout
                }
              : {
                  preset:
                    t.icon &&
                    'islands#'.concat(
                      (function(t) {
                        return (
                          (t.icon.color || 'blue') +
                          (t.icon.glyph ? m(t.icon.glyph) : t.icon.content ? 'Stretchy' : '')
                        );
                      })(t),
                      'Icon'
                    )
                },
            c = t.markerStroke
              ? {
                  strokeColor: t.markerStroke.color || '0066ffff',
                  strokeOpacity:
                    0 <= parseFloat(t.markerStroke.opacity)
                      ? parseFloat(t.markerStroke.opacity)
                      : 1,
                  strokeStyle: t.markerStroke.style,
                  strokeWidth:
                    0 <= parseFloat(t.markerStroke.width) ? parseFloat(t.markerStroke.width) : 1
                }
              : {},
            l = t.markerFill
              ? {
                  fill: t.markerFill.enabled || !0,
                  fillColor: t.markerFill.color || '0066ff99',
                  fillOpacity:
                    0 <= parseFloat(t.markerFill.opacity) ? parseFloat(t.markerFill.opacity) : 1,
                  fillImageHref: t.markerFill.imageHref || ''
                }
              : {},
            u = Object.assign(s, c, l, t.balloonOptions, t.options);
          'Circle' === n && (t.coords = [t.coords, t.circleRadius]);
          var p = (function(t, e) {
            var o = e
              ? {
                  type: 'Feature',
                  id: t.properties.markerId,
                  geometry: { type: t.markerType, coordinates: t.coords },
                  properties: t.properties,
                  options: t.options
                }
              : new ymaps[t.markerType](t.coords, t.properties, t.options);
            return (o.clusterName = t.clusterName), o;
          })(
            {
              properties: i,
              options: u,
              markerType: n,
              coords: t.coords,
              clusterName: t.clusterName
            },
            this.useObjectManager,
            this.$emit
          );
          this.useObjectManager ||
            (this.markerEvents.length ? this.markerEvents : b).forEach(function(e) {
              return p.events.add(e, function(t) {
                return o.$emit(e, t);
              });
            });
          return this.$emit('target', p), p;
        }
      },
      beforeDestroy: function() {
        this.unwatchArr.forEach(function(t) {
          return t();
        }),
          this.deleteMarker(this.markerId);
      }
    };
  (h.install = function t(e) {
    var o = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
    t.installed ||
      ((t.installed = !0),
      (function(t) {
        'function' == typeof t.extend &&
          (u = t.extend({
            props: ['marker', 'component'],
            template:
              '<component :is="component" v-bind="{ marker, ...props.balloonComponentProps }" v-on="listeners" />'
          }));
      })(e),
      (h.pluginOptions = o),
      e.component('yandex-map', h),
      e.component('ymap-marker', v));
  }),
    'undefined' != typeof window && window.Vue && window.Vue.use(h);
  var k = l,
    C = h,
    O = v;
  (t.loadYmap = k),
    (t.yandexMap = C),
    (t.ymapMarker = O),
    (t.default = h),
    Object.defineProperty(t, '__esModule', { value: !0 });
});
