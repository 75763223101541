<template>
  <div class="flex align-start">
    <div class="flex-1">
      <router-link
        class="flex align-center sulguni mb-7 rocky--text"
        :to="to"
      >
        <r-icon
          class="mr-2"
          icon="arrow-left"
        />
        <div class="mtop">
          Назад
        </div>
      </router-link>
      <r-bulb
        color="fargo"
        :title="isSave ? 'Не сохранено' : ''"
        position="eluno"
        :is-block="true"
        size="marina"
      >
        <template #content>
          <h1 class="ricotta">
            {{ title }}
          </h1>
        </template>
      </r-bulb>
    </div>

    <!-- User avatar (profile) -->
    <!-- <div class="flex align-center" @click="logOut">
      <img
        width="40px"
        style="border-radius: 50%; background-color: #F6F9FE;"
        :src="img"
        alt="Аватар пользователя"
      />
      <div class="ml-2 user-info">
        <p class="user-info__name roquefort">{{ user.fio }}</p>
        <p class="user-info__role сaprino opacity-72 mt-1">{{ user.appealOperatorLevel }}</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import Api from '@/api/Api.js';

export default {
  name: 'AdminHeader',
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'admin' })
    },
    title: {
      type: String,
      required: true
    },
    isSave: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: '/upload/city36/medicine/filename1_6166cc71b28be.jpeg'
    }
  },
  data() {
    return {
      name: null,
      role: null
    };
  }
  // mounted() {
  //   this.$store.dispatch('getUserInfo');
  // },
  // computed: {
  //   user() {
  //     return this.$store.getters.getUser;
  //   }
  // },
  // methods: {
  //   async logOut() {
  //     const api = new Api();
  //     const res = await api.logOut();
  //     console.log('logOut', res);
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.user-info {
  max-width: 132px;
  white-space: nowrap;
  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__role {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
